import React from "react";

import Layout from "../components/Layout/";
import SEO from "../components/Seo";
import SocialLinks from "../components/SocialLinks";

import { MainContent } from "../styles/base";

const AboutPage = () => (
  <Layout>
    <SEO title="About Me" description="Learn a little more about the engineer behind this blog." />
    <MainContent>
      <h1>About Me</h1>
      <p>
        My name is Derrick Mehaffy, I was born in south-east Iowa and I am a solutions engineer at{" "}
        <a href="https://strapi.io" target="_blank" rel="noopener noreferrer">
          Strapi
        </a>
        , and currently living in Phoenix, Arizona USA.
      </p>

      <p>
        Previously I was in data center operations, including a bit of linux system engineering and
        jr. network engineering at{" "}
        <a href="https://qtsdatacenters.com" target="_blank" rel="noopener noreferrer">
          QTS Datacenters
        </a>
        . I found Strapi back in 2017 and started assisting on the project in various capacities,
        but mainly with their documentation, community support, and devops related requests. In
        December of 2018 Strapi flew me from my current location in Phoenix to Paris to speak with
        them at a local conference called API-Days and from there my journey into joining the Strapi
        team started.
      </p>

      <p>
        As of late July 2020, I joined the Strapi team in an official capacity as a Solutions
        engineer to help with managing support for the community, our customers, and even within the
        company.
      </p>

      <p>
        While my Javascript skills (both front and back) aren't super strong, my goal is to grow
        within my role and build up my skills as I go. I started this blog in hopes to document my
        journey into the world of development.
      </p>

      <h2>Contact</h2>

      <p>You can contact me through any of my social networks.</p>

      <SocialLinks hideStyle />
    </MainContent>
  </Layout>
);

export default AboutPage;
